import { useCallback, useEffect, useState } from 'react'
import { jwtDecode } from 'jwt-decode'

interface AuthData {
  token: string | null
  origin?: string | null
  authCode?: string | null
}

let initialized = false
let authCode: string | null

/**
 * The hook is responsible for loading the token from fragment and storing in local storage
 *
 * @returns The token
 */
export const useAuthToken = () => {
  const [results, setResults] = useState<AuthData>({
    token: null,
    origin,
    authCode,
  })

  useEffect(() => {
    if (!initialized) {
      initialized = true
      const urlParams = new URLSearchParams(window.location.search)

      window.appStorage.removeItem('token-fetching')

      // hotreload detects if the page was hot refreshed via react watch
      const hotReload = window.performance.getEntriesByType
        ? window?.performance
            .getEntriesByType('navigation')
            .map((nav: PerformanceEntry) => {
              return (nav as PerformanceNavigationTiming).type
            })
            .includes('reload')
        : false

      authCode = urlParams.get('authCode')

      if (window.appStorage.getItem('authCode') !== authCode && !hotReload) {
        window.appStorage.removeItem('origin')
        window.appStorage.removeItem('token')
      }
    }
  }, [])

  useEffect(() => {
    Object.keys(results).forEach((key) => {
      const value = results[key as keyof AuthData]
      if (results.token && typeof value !== 'undefined' && value !== null) {
        window.appStorage.setItem(key, value.toString())
        window.dispatchEvent(new Event('storage-changed'))
      }
    })
  }, [results])

  const getToken = useCallback(
    async (newAuthCode?: string | null) => {
      window.appStorage.removeItem('origin')

      const isFetching = {
        get: () => window.appStorage.getItem('token-fetching'),
        set: () => window.appStorage.setItem('token-fetching', 'true'),
        remove: () => window.appStorage.removeItem('token-fetching'),
      }

      const urlParams = new URLSearchParams(window.location.search)
      const currentAuthCode = newAuthCode ?? authCode

      if (isFetching.get()) {
        // listen for token arrive if a prev fetch was in progress and set results:
        // (it makes sure that all useAuthToken hooks return the token)
        const onStorageChange = () => {
          if (!isFetching.get()) {
            const token = window.appStorage.getItem('token')

            const origin = window.appStorage.getItem('origin')
            if (!token) return

            setResults((res) => ({
              ...res,
              ...{ token, origin },
            }))
            window.removeEventListener('storage-changed', onStorageChange)
          }
        }
        window.addEventListener('storage-changed', onStorageChange)
      } else {
        isFetching.set()

        const response = await fetch(`${window.xundEnvironment.PP_ID_API_URL}/token`, {
          method: 'POST',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            clientId: urlParams.get('clientId'),
            code: currentAuthCode,
            grant_type: 'authorization_code',
          }),
        })
        isFetching.remove()

        const responseJson = await response.json()

        if (responseJson) {
          const { access_token: token, origin } = responseJson

          const exp = jwtDecode(token)?.exp

          if (token !== results.token) setResults({ token, origin, authCode })

          if (exp) {
            const now = new Date().getTime()
            setTimeout(() => {
              // response from embed.js with the new authCode:
              const onMessage = (event: MessageEvent) => {
                if (event.data.authCode) {
                  authCode = urlParams.get('authCode')
                  // setAuthCode(event.data.authCode)
                  getToken(event.data.authCode)
                  window.removeEventListener('message', onMessage)
                }
              }
              window.addEventListener('message', onMessage, false)

              // ask embed.js for new authCode:
              window.parent.postMessage({ refreshToken: true }, '*')
            }, exp * 1000 - now - 10 * 60 * 1000) // 50mins (runs 10mins before token expiration)
          }
        }
      }
    },
    [results],
  )

  useEffect(() => {
    if (authCode) {
      const tokenFromFragment = window.location.hash?.slice(1)

      const fetchToken = async () => {
        const urlParams = new URLSearchParams(window.location.search)
        if (!urlParams.get('clientId')) {
          throw new Error('No clientId provided')
        } else {
          await getToken()
        }
      }

      const shouldUseCachedAuth = !!window.appStorage?.getItem('token')

      if (shouldUseCachedAuth) {
        const token = window.appStorage?.getItem('token')

        const origin = window.appStorage?.getItem('origin')

        if (token !== results.token) {
          setResults({ token, origin })
        }
      } else if (tokenFromFragment) {
        window.location.replace('#')
        if (typeof window.history.replaceState == 'function') {
          history.replaceState({}, '', window.location.href.slice(0, -1))
        }

        setResults({ token: tokenFromFragment })
      } else {
        fetchToken()
      }
    }
  }, [getToken, results.token])

  return { ...results, ...{ authCode } }
}
