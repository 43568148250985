import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ChainedBackend from 'i18next-chained-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import { defaultWebAppConfig } from './resources/defaultWebAppConfig'

export const setupI18n = () => {
  i18n
    .use(ChainedBackend)
    .use(initReactI18next)
    .init({
      backend: {
        backends: [
          resourcesToBackend((language: string): Promise<Record<string, string>> => {
            let res: Promise<Record<string, { translation: string; notes?: string }>>

            // all formal language variants should have a language code format like "de-formal" and should have a case without the - sign below like e.g. "deformal"
            // TODO find another solution on refactor day
            switch (language) {
              case 'en':
                res = import('./locales/en.json').then((module) => module.default)
                break
              case 'de':
                res = import('./locales/de.json').then((module) => module.default)
                break
              case 'deformal': // de-formal value from appStorage would be replaced to de-FORMAL by react-i18next which would cause this switch to step into the the default case incorrectly
                res = import('./locales/de-formal.json').then((module) => module.default)
                break
              case 'fr':
                res = import('./locales/fr.json').then((module) => module.default)
                break
              case 'frformal': // fr-formal value from appStorage would be replaced to fr-FORMAL by react-i18next which would cause this switch to step into the the default case incorrectly
                res = import('./locales/fr-formal.json').then((module) => module.default)
                break
              case 'hu':
                res = import('./locales/hu.json').then((module) => module.default)
                break
              case 'nl':
                res = import('./locales/nl.json').then((module) => module.default)
                break
              case 'nlformal': // nl-formal value from appStorage would be replaced to nl-FORMAL by react-i18next which would cause this switch to step into the the default case incorrectly
                res = import('./locales/nl-formal.json').then((module) => module.default)
                break
              case 'pt':
                res = import('./locales/pt.json').then((module) => module.default)
                break
              case 'ptformal': // pt-formal value from appStorage would be replaced to pt-FORMAL by react-i18next which would cause this switch to step into the the default case incorrectly
                res = import('./locales/pt-formal.json').then((module) => module.default)
                break
              default:
                res = import('./locales/en.json').then((module) => module.default)
                break
            }

            return res.then((result) => {
              return Object.keys(result).reduce((previousValue, currentValue) => {
                return { ...previousValue, [currentValue]: result[currentValue].translation }
              }, {})
            })
          }),
        ],
      },
      // formal language variants must be transformed to avoid being replaced by react-i18next to a wrong language code
      lng: window.appStorage.getItem('xundWebAppLanguage')?.replace('-', '') || defaultWebAppConfig.defaultLanguage,
      fallbackLng: false,
    })
}
