import { useState } from 'react'
import { useApiGatewayContext } from '../context'
import { triggerBrowserDownload } from '../utils/triggerBrowserDownload'

export const useDownloadReportAsPDF = () => {
  const { apiGateway } = useApiGatewayContext()

  const [isDownloading, setIsDownloading] = useState(false)
  const download = async () => {
    setIsDownloading(true)

    const { data } = await apiGateway.get<Blob>('v1/chatbot/report/pdf', { responseType: 'blob' })

    triggerBrowserDownload(data, 'xund_report.pdf')

    setIsDownloading(false)
  }
  return { download, isDownloading }
}
