import styled from '@emotion/styled'
import { theme } from 'antd'

// TODO migrate MainPage.module.less to styled components

export const PageContainer = styled.div<{
  isContentLibraryOpen?: boolean
  isServicesOpen: boolean
  isOnStartScreen: boolean
  isReport: boolean
}>(
  `
  width: 100%;
  
  display: flex;
  flex-direction: column;
  &>.ant-row{
      flex-grow:1;
      align-content: flex-start;
    }

  `,
  (props) => props.isServicesOpen && `overflow: hidden;`,
  (props) => !props.isOnStartScreen && `margin-top: 50px;`,
  (props) =>
    props.isContentLibraryOpen
      ? `
    flex-grow:1;
    align-content: flex-start;
    `
      : `
    flex: 1;`,
  (props) =>
    !props.isReport &&
    `&>.ant-row .ant-col{
    min-height:100%
  }`,
)

export const QuestionHandlerContainer = styled.div<{
  isReporting?: boolean
  shouldMoveFooterButtonsAboveKeyboard: boolean | undefined
  keyboardHeight: number
  featureReportRedesignEnabled?: boolean
}>((props) => {
  const { token } = theme.useToken()
  return `
    height: ${props.isReporting ? `auto` : `100%`};
    width: 100%;
    ${props.shouldMoveFooterButtonsAboveKeyboard ? `padding-bottom: ${props.keyboardHeight}px;` : ''}
    ${
      props.featureReportRedesignEnabled
        ? `
      @media only screen and (max-width: ${token.screenSM - 1}px) {
      margin: 0;
    }
      `
        : ''
    }
    
  
`
})
