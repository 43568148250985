import { Question } from '../models'

export const startQuestion: Question = {
  detailedType: 'START_SCREEN',
  id: '',
  text: '',
  type: 'SINGLE_SELECT',
  isSkippable: false,
  isAnswered: false,
  checkStateInfo: { state: 'START', detailedState: 'INITIAL' },
}

export const legalDisclaimer: Question = {
  detailedType: 'TERMS_AND_CONDITIONS',
  id: '',
  text: '',
  type: 'SINGLE_SELECT',
  isSkippable: false,
  isAnswered: false,
  checkStateInfo: { state: 'START', detailedState: 'INITIAL' },
}

export const patientIntentBeforeCheck: Question = {
  detailedType: 'PATIENT_INTENT_BEFORE_CHECK',
  id: 'PATIENT_INTENT_BEFORE_CHECK_ID',
  text: '',
  type: 'SINGLE_SELECT',
  isSkippable: true,
  isAnswered: false,
  checkStateInfo: { state: 'START', detailedState: 'INITIAL' },
}

export const loading: Question = {
  detailedType: 'LOADING',
  id: '',
  text: '',
  type: 'SINGLE_SELECT',
  isSkippable: false,
  isAnswered: false,
  checkStateInfo: { state: 'START', detailedState: 'INITIAL' },
}

export const questionIdsWithoutAnswerSaving = ['PATIENT_INTENT_BEFORE_CHECK_ID']
export const questionDetailedTypesGoToStartOnBackButton = ['TERMS_AND_CONDITIONS', 'PATIENT_INTENT_BEFORE_CHECK']
