import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useApiGatewayContext } from '../../../context'
import { useApiKeyScopes, useI18n } from '../../../hooks'
import { Imprint } from '../../../models'
import { LoadingIndicator } from '../../../common/Indicators/LoadingIndicator'
import { BaseModal } from '../BaseModal/BaseModal'
import styles from './Impressum.module.less'
import axios from 'axios'
import { ScIcImpressumData } from './ScIcImpressumData'
import { HealthCheckImpressumData } from './HealthCheckImpressumData'
import { HealthCheckImprint } from 'health-check-api'
import { ImpressumContactData } from './ImpressumContactData'

let scIcImpressumDataCache: Imprint | null
let healthCheckImpressumDataCache: HealthCheckImprint | null

/**
 * @param props The props object
 * @param props.onCancel The method handling cancellation
 * @returns The Impressum component
 */
export const Impressum: FC<{ onCancel: () => void }> = ({ onCancel }) => {
  const [scIcImpressumData, setScIcImpressumData] = useState<Imprint | null>(null)
  const [healthCheckImpressumData, setHealthCheckImpressumData] = useState<HealthCheckImprint | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error>()

  const { i18n } = useI18n()
  const { apiGateway } = useApiGatewayContext()
  const { hasApiKeyScope } = useApiKeyScopes()

  const isHealthCheckImprintEnabled =
    window.xundEnvironment.IMPRINT_ENDPOINT_ENABLED === 'true' && hasApiKeyScope('execute_health_checks')

  const healthCheckApi = useMemo(() => {
    return axios.create({
      baseURL: window.xundEnvironment.HEALTH_CHECK_API_BASE_URL,
      timeout: 30000,
    })
  }, [])

  const getImpressumData = useCallback(async () => {
    try {
      setIsLoading(true)

      const { data } = await apiGateway.get(`v1/imprintResources`)
      scIcImpressumDataCache = data
      setScIcImpressumData(data)
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsLoading(false)
    }
  }, [apiGateway])

  const getHealthCheckImpressumData = useCallback(async () => {
    if (!isHealthCheckImprintEnabled) {
      return
    }

    try {
      setIsLoading(true)

      const { data } = await healthCheckApi.get(`/imprint`)
      healthCheckImpressumDataCache = data
      setHealthCheckImpressumData(data)
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsLoading(false)
    }
  }, [healthCheckApi, isHealthCheckImprintEnabled])

  useEffect(() => {
    if (!scIcImpressumDataCache) {
      getImpressumData()
    }
    if (!healthCheckImpressumDataCache) {
      getHealthCheckImpressumData()
    }
    if (scIcImpressumDataCache) {
      setScIcImpressumData(scIcImpressumDataCache)
    }
    if (healthCheckImpressumDataCache) {
      setHealthCheckImpressumData(healthCheckImpressumDataCache)
    }
  }, [getHealthCheckImpressumData, getImpressumData])

  if (error) {
    throw error
  }

  return (
    <BaseModal
      title={i18n('xund.imprint.title')}
      onCancel={onCancel}
      hasButtons={false}
      contentClassName={styles.impressum}
      isImpressumModal
    >
      {!isLoading && (
        <>
          {(scIcImpressumData || healthCheckImpressumData) && (
            <ImpressumContactData scIcImprint={scIcImpressumData} hcImprint={healthCheckImpressumData} />
          )}
          {scIcImpressumData && <ScIcImpressumData imprint={scIcImpressumData} />}
          {healthCheckImpressumData && <HealthCheckImpressumData imprint={healthCheckImpressumData} />}
        </>
      )}
      {isLoading && <LoadingIndicator delay={200} inline />}
    </BaseModal>
  )
}
